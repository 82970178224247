<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>

      <div
        class="flex flex-col items-center justify-start w-full text-yellow-50 p-3 mb-28"
      >
        <div class="py-1 max-w-5xl w-full flex-none">
          <div class="p-4">
            <div>
              <a
                v-on:click="$router.go(-1)"
                class="flex items-center space-x-2 text-center cursor-pointer"
                ><i class="fad fa-arrow-left mr-1"></i><span>Go Back</span></a
              >
            </div>
          </div>
        </div>
        <div class="py-4 space-y-6 md:py-8 lg:py-12 max-w-2xl w-full -mt-5">
          <h2
            class="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-green-800"
          >
            Verify You identify
          </h2>
          <h3
            class="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-gray-400 to-gray-600"
          >
            It will only take about 2 minutes
          </h3>
          <div class="p-1">
            <div>
              <div class="">
                <div class="w-full">
                  <div class="flex flex-col">
                    <div>
                      <div class="flex font-semibold mt-2">
                        <span
                          class="flex-grow leading-tight text-gray-500 text-xs sm:text-base"
                        >
                          Phone Number
                        </span>
                      </div>
                      <form class="mt-2">
                        <div
                          class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-xl"
                        >
                          <input
                            placeholder="-"
                            v-model="phone"
                            class="flex-grow w-full outline-none text-lg bg-transparent"
                          />
                        </div>
                      </form>
                    </div>
                  </div>

                  <div class="flex flex-col mt-5">
                    <div>
                      <div class="flex font-semibold mt-2">
                        <span
                          class="flex-grow leading-tight text-gray-500 text-xs sm:text-base"
                        >
                          Email
                        </span>
                      </div>
                      <form class="mt-2">
                        <div
                          class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-xl"
                        >
                          <input
                            v-model="email"
                            placeholder="-"
                            class="flex-grow w-full outline-none text-lg bg-transparent"
                          />
                        </div>
                      </form>
                    </div>
                  </div>

                  <div class="flex flex-col mt-5">
                    <div>
                      <div class="flex font-semibold mt-2">
                        <span
                          class="flex-grow leading-tight text-gray-500 text-xs sm:text-base"
                        >
                          Name
                        </span>
                      </div>
                      <form class="mt-2">
                        <div
                          class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-xl"
                        >
                          <input
                            v-model="name"
                            placeholder="-"
                            class="flex-grow w-full outline-none text-lg bg-transparent"
                          />
                        </div>
                      </form>
                    </div>
                  </div>

                  <div
                    @click="updateKYC()"
                    class="flex mt-10 border-t border-l border-blue-500 shadow-md border-opacity-25 py-1 px-3 bg-gradient-to-r from-green-600 to-green-800 rounded-lg bg-opacity-20 mb-2 items-center text-center"
                  >
                    <div class="flex-grow cursor-pointer">
                      <h3
                        class="p-3 text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-gray-100 to-gray-200"
                      >
                        Continue
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div></div>
        <footer>
          <StickFooter />
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/StickFooter.vue";
import { mapGetters, mapActions } from "vuex";

import kyc from "@/api/kyc";
import nativeToast from "native-toast";

import Swal from "sweetalert2";

export default {
  data() {
    return {
      address: this.$route.params.address,
      phone: null,
      email: null,
      name: null,
    };
  },
  components: {
    ConnectWallet,
    StickFooter,
  },
  methods: {
    computed: mapGetters(["getBalance", "getCurrency", "getUser"]),

    fixed(a) {
      return (a.toFixed(0) / 1).toLocaleString();
    },
    digit(a, digi) {
      if (a) {
        return a.toLocaleString(undefined, {
          minimumFractionDigits: digi,
        });
      }
    },
    updateKYC() {
      if (this.email != null && this.phone != null) {
        console.log("ssss");

        kyc
          .updateKYC({
            email: this.email,
            phone: this.phone,
            address: this.address,
            name: this.name,
          })
          .then(async (res) => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "We have received the information and wait approve by admin 24 hour",
            }).then((result) => {
              if (result.isConfirmed) {
                this.$router.push({ path: "/" });
              }
            });
          });
      }
    },

    async getDataKYC() {
      await kyc
        .byUser({
          address: this.address,
        })
        .then((res) => {
          if (res.data.data) {
            this.email = res.data.data.email;
            this.phone = res.data.data.phone;
            this.address = res.data.data.address;
            this.name = res.data.data.name;
          }
        })
        .catch((error) => {});
    },
  },
  created() {
    this.getDataKYC();
  },
};
</script>
